<template lang="pug">
.cv-box
  XyzTransition(appear, xyz="fade up-2 delay-2")
    .cv-image
      img(:src="curriculum.snapshot_base64")
  .cv-options
    .cv-name {{ curriculumData.ProfileData.profile_name || '--' }}
    .cv-edited Última edición: {{ last_update }}
    ul
      li 
        .item-link.hard-blue(@click="downloadCurriculum")
          BaseCustomIcon.text-blue-600(nameIcon="mdiDownload", :size="18")
          span Descargar
      li
        .item-link.yellow(@click="editCurriculum")
          BaseCustomIcon.text-yellow-500(nameIcon="mdiFileEdit", :size="18")
          span Editar
      li
        .item-link.light-blue(@click="cloneCurriculum")
          BaseCustomIcon.text-blue-400(nameIcon="mdiContentCopy", :size="18")
          span Duplicar
      li
        .item-link.green
          BaseCustomIcon.text-green-600(
            nameIcon="mdiShareVariantOutline",
            :size="18"
          )
          span Compartir
      li
        .item-link.red(@click="deleteCurriculum")
          BaseCustomIcon.text-red-600(
            nameIcon="mdiTrashCanOutline",
            :size="18"
          )
          span Eliminar
</template>

<script>
import parserDate from "@/composables/parse-date";
import DownloadFile from "@/composables/download-file";
import { UsersRepository } from "@/repository/users.repository";
export default {
  name: "curriculum-card",
  emits: ["reloadList"],
  props: {
    curriculum: {
      type: Object,
      required: true,
    },
  },
  created() { },
  computed: {
    last_update() {
      const { parseDate } = parserDate();
      return parseDate(this.curriculum.updated_at);
    },
    curriculumData() {
      return JSON.parse(this.curriculum.curriculum_data)
    }
  },
  methods: {
    downloadCurriculum() {
      UsersRepository.downloadCurriculum(this.curriculum._id)
        .then((curriculum) => {
          const { downloadFile } = DownloadFile();
          downloadFile(curriculum.pdf_base64)
            .then(() => {
              this.$store.dispatch("UserStore/loadMeData");
            })
            .catch(function (error) { });
        })
        .catch(({ response }) => {
          if (
            response.data.message &&
            response.data.message == "disabled-downloads"
          ) {
            this.$router.push({
              name: "builder/payment/with-id",
              params: { curriculum_id: this.curriculum._id },
            });
          }
        });
    },
    async cloneCurriculum() {
      UsersRepository.cloneCurriculum(this.curriculum._id)
        .then(() => {
          this.$emit("reloadList");
        })
        .catch((e) => console.log(e));
    },
    async deleteCurriculum() {
      UsersRepository.deleteCurriculum(this.curriculum._id)
        .then(() => {
          this.$emit("reloadList");
        })
        .catch((e) => console.log(e));
    },
    async editCurriculum() {
      this.$router.push({
        name: "builder/creator/with-id",
        params: { curriculum_id: this.curriculum._id },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.cv-box {
  background-color: rgba(239, 246, 255, 0.5);
  @apply flex  w-full border rounded-lg border-blue-100 shadow-sm;
  .cv-image {
    width: 190px;
    max-height: 320px;
    @apply rounded-lg border-4 border-blue-100 overflow-hidden ml-4 -mt-4 mb-4 flex-shrink-0 shadow-sm;
  }
  .cv-options {
    @apply flex h-full flex-col justify-center p-4 flex-grow overflow-hidden;

    .cv-name {
      @apply text-2xl whitespace-nowrap overflow-ellipsis overflow-hidden;
    }
    .cv-edited {
      @apply text-xs whitespace-nowrap overflow-ellipsis overflow-hidden text-gray-400 mb-2;
    }

    ul {
      @apply p-2 pl-0;
      li {
        @apply cursor-pointer;
        & + li {
          @apply mt-2;
        }
        .item-link {
          @apply flex items-center;
          svg {
            @apply flex-shrink-0;
          }
          span {
            @apply font-normal text-sm pl-1 transition-colors text-gray-500;
          }

          &.hard-blue {
            &:hover {
              span {
                @apply font-medium text-blue-600;
              }
            }
          }
          &.yellow {
            &:hover {
              span {
                @apply font-medium text-yellow-600;
              }
            }
          }
          &.light-blue {
            &:hover {
              span {
                @apply font-medium text-blue-400;
              }
            }
          }
          &.green {
            &:hover {
              span {
                @apply font-medium text-green-600;
              }
            }
          }
          &.red {
            &:hover {
              span {
                @apply font-medium text-red-600;
              }
            }
          }
        }

        &:hover {
          @apply font-medium;
        }
      }
    }
  }

  @media (min-width: 960px) {
    .cv-options ul li .item-link span {
      @apply text-base;
    }
  }
}
</style>
