<template lang="pug">
.container.mt-12.mb-24
  baseH1Pages Mis Curriculums
  XyzTransitionGroup.cvs-wrapper(appear, xyz="fade down-2 stagger")
    CurriculumCardComponent(
      v-for="item in listCurriculums",
      :key="item._id",
      :curriculum="item",
      v-on:reloadList="loadListCv"
    )
  .button-new-cv(@click="createCurriculum")
    .action-button Crear nuevo CV
</template>


<script>
import CurriculumCardComponent from "@/components/www/dashboard/curriculum-card/curriculum-card.vue";
import { UsersRepository } from "@/repository/users.repository";

export default {
  name: "my-cvs",
  components: {
    CurriculumCardComponent,
  },
  data: () => ({
    listCurriculums: [],
  }),
  async created() {
    await this.loadListCv();
  },
  methods: {
    async loadListCv() {
      const listCv = await UsersRepository.listCurriculums();
      this.listCurriculums = listCv;
    },
    async createCurriculum() {
      const newCv = await UsersRepository.createCurriculum();
      this.$router.push({
        name: "builder/select-template/with-id",
        params: { curriculum_id: newCv._id },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  max-width: 1024px !important;
}

.cvs-wrapper {
  @apply grid grid-cols-1 gap-12 mt-20 mb-12;
}

.button-new-cv {
  @apply flex items-center justify-center;
}

.action-button {
  @apply text-xl px-12 py-4 text-center bg-blue-500 text-white rounded-lg font-medium cursor-pointer inline-block transition-opacity;
}

@media (min-width: 960px) {
  .cvs-wrapper {
    @apply grid-cols-2;
  }
}
</style>
